export class QualityError {
  date: Date;
  code: string;
  file: string;
  poId: string;
  constructor(error?: Partial<QualityError>) {
    this.date = error?.date ?? new Date();
    this.code = error?.code ?? '';
    this.file = error?.file ?? '';
    this.poId = error?.poId ?? '';
  }
}
