import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ImportOrder} from '../models/import-order.model';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe
} from '@angular/fire/firestore';
import {BehaviorSubject, lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  private unsubscribeImportOrders: Unsubscribe;
  private importOrders$: BehaviorSubject<ImportOrder[]>;

  constructor(private firestore: Firestore,
              private http: HttpClient) {
  }

  /**
   * Get imported orders from PrintOCLock sorted by date desc
   * @return The query to get imports
   */
  public getImports(): BehaviorSubject<ImportOrder[]> {
    if (!this.importOrders$) {
      this.importOrders$ = new BehaviorSubject<ImportOrder[]>([]);
      const q = query(
        collection(this.firestore, 'imports'),
        orderBy('date', 'desc'),
        limit(10))
        .withConverter(ImportOrder.converter);
      this.unsubscribeImportOrders = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.importOrders$.next([]);
        }
        this.importOrders$.next(snapshot.docs.map(doc => doc.data()));
      })
    }
    return this.importOrders$;
  }

  /**
   * Delete an imported order
   * @param id The id of the import
   */
  public delete(id: string): Promise<void> {
    return deleteDoc(doc(this.firestore, "imports", id));
  }

  /**
   * Call API to push order into Multipress
   * @param order The order to import
   */
  public startImport(order: ImportOrder): Promise<void> {
    const apiUrl = environment.functionsUrlEuropeWest1 + 'push_imports';
    const data = {order};
    return lastValueFrom(this.http.post<void>(apiUrl, data));
  }

  /**
   * Call API to print production file
   * @param order The order to print
   */
  public print(order: ImportOrder): Promise<{html}> {
    const apiUrl = environment.functionsUrlEuropeWest1 + 'printoclock_order_print';
    const data = {reference: order.id};
    return lastValueFrom(this.http.post<{html}>(apiUrl, data));
  }

  /**
   * Call API get an order form PrintOClock
   * @param reference The order reference
   */
  public getOrder(reference: string): Promise<void> {
    const apiUrl = environment.functionsUrlEuropeWest1 + 'get_printoclock_order';
    const data = {reference};
    return lastValueFrom(this.http.post<void>(apiUrl, data));
  }

  public unsubscribe() {
    if (this.unsubscribeImportOrders) {
      this.unsubscribeImportOrders();
      this.importOrders$ = null;
    }
  }
}
