export enum RolesEnum  {
    BASIC_READ = '', // Basic default role that every logged in user is given (not stored in the database)
    // Commerce
    QUOTATION_WRITE = 'quotation_write',
    CUSTOMER_WRITE = 'customer_write',
    PRODUCT_WRITE = 'product_write',
    COMMERCE_WRITE = 'commerce_write',
    ORDER_READ = 'order_read',
    // Pre-press
    ORDER_WRITE = 'order_write',
    SCRIPT_WRITE = 'script_write',
    // Production
    PRODUCTION_WRITE = 'production_write',
    MACHINE_WRITE = 'machine_write',
    // Subcontracting
    INSTALLATION_WRITE = 'installation_write',
    EXTERNAL_PRODUCTION_WRITE = 'external_production_write',
    SUBCONTRACTOR_WRITE = 'subcontractor_write',
    // Transport
    SHIPMENT_READ = 'shipment_read',
    SHIPMENT_WRITE = 'shipment_write',
    WORKSHOP_WRITE = 'workshop_write',
    // Administration
    DATABASE_WRITE = 'database_write',
    CONFIGURATION_WRITE = 'configuration_write',
    USER_WRITE = 'user_write',
    SETTING_WRITE = 'setting_write',
    // Plugins
    IMPORT_WRITE = 'import_write',
    // TODO: Remove it
    ADDRESS_WRITE = 'address_write',
}
