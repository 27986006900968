import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';

export class Finish {
  id: string;
  erpId: string;
  description: string;
  value: FinishEnum;

  constructor(data?: Partial<Finish>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.value = data?.value ?? FinishEnum.NONE;
  }

  static toFirestore(finish: Finish) {
    return {
      id: finish.id,
      erpId: finish.erpId,
      description: finish.description,
      value: finish.value,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Finish(snapToData(snapshot, {idField: 'id'}));
  }
}

export enum FinishEnum {
  NONE = 'none',
  GLOSSY = 'glossy',
  MATE = 'mate',
  GRAFFITI = 'graffiti',
  SELECTIVE = 'selective',
  RAW = 'raw',
  HEMS = 'hems',
  CHANNELS = 'channels',
}