import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';

export class Drilling {
  id: string;
  erpId: string;
  description: string;
  distance: number;
  amount: number;
  eyelet: boolean;

  constructor(data?: Partial<Drilling>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.distance = data?.distance ?? 0;
    this.amount = data?.amount ?? 0;
    this.eyelet = data?.eyelet ?? false;
  }

  static toFirestore(drilling: Drilling) {
    return {
      id: drilling.id,
      erpId: drilling.erpId,
      description: drilling.description,
      distance: drilling.distance,
      amount: drilling.amount,
      eyelet: drilling.eyelet,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Drilling(snapToData(snapshot, {idField: 'id'}));
  }
}
