import { FirestoreDataConverter } from '@angular/fire/firestore';
import { PaymentPlatformEnum } from '../enums/payment-platform.enum';

export class PaymentPlatform {
  id?: string;
  shop: string;
  platform: PaymentPlatformEnum;
  secretKey: string;
  hookKey: string;

  constructor(data?: Partial<PaymentPlatform>) {
    this.id = data?.id ?? '';
    this.shop = data?.shop ?? '';
    this.platform = data?.platform ?? PaymentPlatformEnum.UNKNOWN;
    this.secretKey = data?.secretKey ?? '';
    this.hookKey = data?.hookKey ?? '';
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<PaymentPlatform> = {
    toFirestore: (platform: PaymentPlatform) => {
      return {
        id: platform.id,
        shop: platform.shop,
        platform: platform.platform,
        secretKey: platform.secretKey,
        hookKey: platform.hookKey,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new PaymentPlatform({
        ...data,
        id: snapshot.id,
      });
    }
  };
}
