export class ObjectHelper {
  /**
   * Transform a (array of) typed object into a (array of) POJO
   * @param {any} obj
   */
  static anonymize(obj: any): any {
    if (!obj || typeof obj !== 'object' || obj instanceof Date) {
      return obj;
    }
    const target = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      target[key] = ObjectHelper.anonymize(obj[key])
    }
    return target;
  }
}
