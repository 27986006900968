import { FirestoreDataConverter } from '@angular/fire/firestore';
import { RolesEnum } from "../enums/roles.enum";

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  advanced: boolean;
  sales: boolean;
  productionNotification: boolean;
  company: string;
  roles: RolesEnum[];
  customerIds: number[];
  subcontractorIds: string[];

  constructor(user?: Partial<User>) {
    this.id = user?.id ?? '';
    this.email = user?.email ?? '';
    this.firstName = user?.firstName ?? '';
    this.lastName = user?.lastName ?? '';
    this.photoURL = user?.photoURL ?? '';
    this.advanced = user?.advanced ?? false;
    this.sales = user?.sales ?? false;
    this.productionNotification = user?.productionNotification ?? false;
    this.company = user?.company ?? '';
    this.roles = [RolesEnum.BASIC_READ].concat(user?.roles ?? []);
    this.customerIds = user?.customerIds ?? [];
    this.subcontractorIds = user?.subcontractorIds ?? [];
  }

  get displayName(): string {
    if (!this.firstName && !this.lastName) {
      return this.email;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Converter used to convert a user from firebase
   */
  static converter: FirestoreDataConverter<User> = {
    toFirestore: (user: User) => ({
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      photoURL: user.photoURL,
      advanced: user.advanced,
      sales: user.sales,
      productionNotification: user.productionNotification,
      company: user.company,
      roles: user.roles,
      customerIds: user.customerIds,
      subcontractorsIds: user.subcontractorIds,
    }),
    fromFirestore: (snapshot, options) => new User({ ...snapshot.data(options), id: snapshot.id })
  };
}
