import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class VerifiedGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
  }

  canActivate(): Promise<boolean> {
    if (!this.authService.isVerified) {
      return this.router.navigate(['']);
    }
    return Promise.resolve(true);
  }
}
