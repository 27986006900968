import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CommerceProduct } from "../models/commerce-product.model";
import {
  addDoc,
  collection, deleteDoc,
  doc, Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query, setDoc,
  snapToData, Unsubscribe, updateDoc,
  where
} from "@angular/fire/firestore";
import { ImageModel } from "../models/image.model";
import { UploadService } from "./upload.service";
import { ObjectHelper } from "../helpers/object.helper";

@Injectable({
  providedIn: 'root',
})
export class ProductService {

  private uploadService= inject(UploadService);
  private firestore= inject(Firestore);

  private products$: BehaviorSubject<CommerceProduct[]>;
  private unsubscribeProducts: Unsubscribe;

  /**
   * Get all the products
   * @returns The observable for products
   */
  public products(): BehaviorSubject<CommerceProduct[]> {
    if (!this.products$) {
      this.products$ = new BehaviorSubject<CommerceProduct[]>([]);
      const q = query(collection(this.firestore, 'product'))
        .withConverter(CommerceProduct.converter);
      this.unsubscribeProducts = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.products$.next([]);
        }
        this.products$.next(snapshot.docs.map(doc => doc.data()));
      });
    }
    return this.products$;
  }

  /**
   * Get the product with the given id
   * @param id The product id
   * @returns The product
   */
  async product(id: string): Promise<CommerceProduct> {
    const ref = doc(this.firestore, 'product', id)
      .withConverter(CommerceProduct.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  /**
   * Get the product with the given name belonging to the given configuration
   * @param name The product name
   * @returns First product with same name
   */
  async productByTechnicalName(name: string): Promise<CommerceProduct> {
    const q = query(
      collection(this.firestore, 'product'),
      where('technicalName', '==', name))
      .withConverter(CommerceProduct.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return null;
        }
        return new CommerceProduct(snapToData(snapshot.docs[0], { idField: 'id' }) as CommerceProduct);
      })
  }

  /**
   * Get products linked with the configuration id
   * @param id The configuration id
   * @returns Linked products
   */
  async productByConfiguration(id: string): Promise<CommerceProduct[]> {
    const q = query(
      collection(this.firestore, 'product'),
      where('variations', 'array-contains', id))
      .withConverter(CommerceProduct.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => new CommerceProduct(snapToData(doc, { idField: 'id' })));
  }

  /**
   * Create a new product for the given configuration with the given name
   * @param product The product to create
   * @returns The created product id
   */
  async addProduct(product: Partial<CommerceProduct>): Promise<string> {
    const ref = collection(this.firestore, 'product')
      .withConverter(CommerceProduct.converter);
    return addDoc(ref, product)
      .then(ref => ref.id);
  }

  /**
   * Update the given product
   * @param product The product to update
   * @returns void
   */
  updateProduct(product: Partial<CommerceProduct>): Promise<void> {
    const ref = doc(this.firestore, 'product', product.id)
      .withConverter(CommerceProduct.converter);
    return updateDoc(ref, ObjectHelper.anonymize(product));
  }

  /**
   * Copy the given product, appending (Copy) to its name
   * @param product The product to copy
   * @param technicalName The product to copy
   * @returns The copied product name
   */
  async copyProduct(product: CommerceProduct, technicalName: string): Promise<string> {
    const ref = doc(collection(this.firestore, 'product')).withConverter(CommerceProduct.converter);
    if (product.pdf) {
      const newPdf = product.pdf.replace(product.id, ref.id);
      product.pdf = await this.uploadService.copyFile(product.pdf, newPdf);
    }
    for (const i of product.images) {
      const newPath = i.path.replace(product.id, ref.id);
      i.path = await this.uploadService.copyFile(i.path, newPath);
      const newThumb = i.thumb.replace(product.id, ref.id);
      i.thumb = await this.uploadService.copyFile(i.thumb, newThumb);
    }
    product.id = ref.id;
    product.technicalName = technicalName
    return setDoc(ref, product).then(() => product.id);
  }

  /**
   * Delete the given product
   * @param product The product to delete
   */
  public async deleteProduct(product: CommerceProduct): Promise<void> {
    await this.uploadService.removeFolder(`public/product/${product.id}`);
    await deleteDoc(doc(this.firestore, 'product', product.id));
  }

  /**
   * Update the pdf and pdfName of the product
   * @param id The product id
   * @param pdf The pdf file URL
   * @param pdfName The file name
   * @returns void
   */
  updateProductPdf(id: string, pdf: string|null, pdfName: string|null): Promise<void> {
    const ref = doc(this.firestore, 'product', id)
      .withConverter(CommerceProduct.converter);
    return updateDoc(ref, { pdf, pdfName });
  }

  /**
   * Update the product images
   * @param id The product id
   * @param images The list of product images
   * @returns void
   */
  updateProductImages(id: string, images: ImageModel[]): Promise<void> {
    const ref = doc(this.firestore, 'product', id).withConverter(CommerceProduct.converter);
    return updateDoc(ref, {
      images: images.filter(image => image.path).map(image => ({ ...image }))
    });
  }

  /**
   * Unsubscribe from products
   */
  unsubscribe(): void {
    if (this.unsubscribeProducts) {
      this.unsubscribeProducts();
      this.products$ = null;
    }
  }
}
