import { FirestoreDataConverter } from '@angular/fire/firestore';
import { Format } from './format.model';
import { Material } from './material.model';
import { Printing } from './printing.model';
import { Cutting } from './cutting.model';
import { Drilling } from './drilling.model';
import { Finish } from './finish.model';
import { Binding } from './binding.model';
import { Folding } from './folding.model';

export type OptionObject = {
  'materials': Material,
  'printTypes': Printing,
  'formats': Format,
  'finishes': Finish,
  'cuttings': Cutting,
  'drillings': Drilling,
  'bindings': Binding,
  'foldings': Folding,
}

/**
 * Converters used to convert a OptionObject from firebase
 */
export const OptionConverters: { [option in keyof OptionObject]: FirestoreDataConverter<OptionObject[option]> } = {
  formats: Format,
  materials: Material,
  printTypes: Printing,
  finishes: Finish,
  cuttings: Cutting,
  drillings: Drilling,
  bindings: Binding,
  foldings: Folding,
};
