export class FirebaseErrors {
  static Parse(errorCode: string): string {

    let message: string;

    switch (errorCode) {
      case 'auth/wrong-password':
        message = 'AUTH_ERRORS.ERROR1';
        break;
      case 'auth/network-request-failed':
        message = 'AUTH_ERRORS.ERROR2';
        break;
      case 'auth/too-many-requests':
        message = 'AUTH_ERRORS.ERROR3';
        break;
      case 'auth/user-disabled':
        message = 'AUTH_ERRORS.ERROR4';
        break;
      case 'auth/requires-recent-login':
        message = 'AUTH_ERRORS.ERROR5';
        break;
      case 'auth/email-already-exists':
        message = 'AUTH_ERRORS.ERROR6';
        break;
      case 'auth/user-not-found':
        message = 'AUTH_ERRORS.ERROR7';
        break;
      case 'auth/phone-number-already-exists':
        message = 'AUTH_ERRORS.ERROR8';
        break;
      case 'auth/invalid-phone-number':
        message = 'AUTH_ERRORS.ERROR9';
        break;
      case 'auth/invalid-email':
        message = 'AUTH_ERRORS.ERROR10';
        break;
      case 'auth/cannot-delete-own-user-account':
        message = 'AUTH_ERRORS.ERROR11';
        break;
      case 'auth/email-already-in-use':
        message = 'AUTH_ERRORS.ERROR12';
        break;
      case 'auth/weak-password':
        message = 'AUTH_ERRORS.ERROR13';
        break;
      case 'custom/email-not-verified':
        message = 'AUTH_ERRORS.ERROR15';
        break;
      case 'auth/account-exists-with-different-credential':
        message = 'AUTH_ERRORS.ERROR17';
        break;
      default:
        message = 'AUTH_ERRORS.ERROR14';
        break;
    }

    return message;
  }
}
