import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs, limit,
  onSnapshot,
  query,
  snapToData,
  Unsubscribe,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { Organization } from "../models/organization.model";
import { ObjectHelper } from "../helpers/object.helper";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {

  private firestore = inject(Firestore);
  private unsubscribeOrganization: Unsubscribe;
  public organization$: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null);

  /**
   * Wait until the organisation is loaded
   */
  public async waitOrganization(): Promise<void> {
    while (!this.organization$.value) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  public get currentOrganization(): Organization {
    return this.organization$.value ?? new Organization();
  }

  public set currentOrganization(organization: Organization) {
    this.organization$.next(organization);
  }

  /**
   * Create new organisation
   */
  public create(organization: Partial<Organization>): Promise<DocumentReference<Organization>> {
    const ref = collection(this.firestore, 'organizations')
      .withConverter(Organization.converter);
    return addDoc(ref, new Organization(organization));
  }

  /**
   * Check id organisation id is available
   */
  public async orgIdIsAvailable(organizationId: string): Promise<boolean> {
    const q = query(collection(this.firestore, 'organizations'),
      where('oid', '==', organizationId))
      .withConverter(Organization.converter);
    return getDocs(q)
      .then(snapshot => {
        return snapshot.empty;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * List organizations of a user
   * @param uid The user id
   */
  public async listAdminOrganizations(uid: string): Promise<Organization[]> {
    const q = query(collection(this.firestore, 'organizations'),
      where('administrators', 'array-contains', uid))
      .withConverter(Organization.converter);
    return getDocs(q)
      .then(snapshot => {
        return snapshot.empty ? [] : snapshot.docs.map(doc =>
          new Organization(snapToData(doc, { idField: 'id' }) as Organization));
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * List organizations of a user
   * @param uid The user id
   */
  public async listUserOrganizations(uid: string): Promise<Organization[]> {
    const q = query(collection(this.firestore, 'organizations'),
      where('users', 'array-contains', uid))
      .withConverter(Organization.converter);
    return getDocs(q)
      .then(snapshot => {
        return snapshot.empty ? [] : snapshot.docs.map(doc =>
          new Organization(snapToData(doc, { idField: 'id' }) as Organization));
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Get an organization by id
   * @param id The organization id
   */
  public async get(id: string): Promise<Organization> {
    const ref = doc(this.firestore, 'organizations', id)
      .withConverter(Organization.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  /**
   * Subscribe to the selected organization
   * @param oid The organization oid
   */
  public subscribeOrganization(oid: string): void {
    if (this.currentOrganization.oid === oid) {
      return;
    }
    this.unsubscribe();
    const q =
      query(collection(this.firestore, 'organizations'),
        where('oid', '==', oid), limit(1))
        .withConverter(Organization.converter);
    this.unsubscribeOrganization = onSnapshot(q, snapshot => {
      if (snapshot.empty) {
        this.organization$.next(null);
        return;
      }
      const organization = snapshot.docs[0].data();
      organization.id = snapshot.docs[0].id;
      this.organization$.next(organization);
    });
  }

  /**
   * Get an organization by oid
   * @param organizationId The organization id
   */
  public async getByOid(organizationId: string): Promise<Organization> {
    const q = query(collection(this.firestore, 'organizations'),
      where('oid', '==', organizationId))
      .withConverter(Organization.converter);
    return getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) {
          return null;
        }
        const organization = snapshot.docs[0].data();
        organization.id = snapshot.docs[0].id;
        this.subscribeOrganization(organization.oid);
        return snapshot.docs[0].data();
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  /**
   * Update an organization
   * @param id The organization id
   * @param organization The partial organization object
   */
  public async update(id: string, organization: Partial<Organization>): Promise<void> {
    const ref = doc(this.firestore, 'organizations', id).withConverter(Organization.converter);
    return updateDoc(ref, ObjectHelper.anonymize(organization));
  }

  /**
   * Unsubscribe from current organization
   */
  public unsubscribe(): void {
    if (this.unsubscribeOrganization) {
      this.unsubscribeOrganization();
    }
    this.organization$.next(null);
  }
}
