import {FirestoreDataConverter} from '@angular/fire/firestore';
import {ImportOrderLine} from './import-order-line.model';

export class ImportOrder {
  /** Firestore ID */
  id: string;
  /** The order reference */
  reference: string;
  /** The date of creation */
  date: Date;
  /** The order is pushed to Multipress */
  processed: boolean;
  /** An file import is running */
  isRunning: boolean;
  /** A push to Multipress is running */
  pushIsRunning: boolean;
  /** The type of import (printoclock, GIM ...) */
  type: string;
  /** Multipress contact id */
  customerId: number;
  /** Company name */
  company: string;
  /** Contact address */
  address: string;
  /** Contact address number */
  addressNumber: string;
  /** Contact name */
  contactName: string;
  /** Contact zip code */
  zipcode: string;
  /** Contact city */
  city: string;
  /** Contact email */
  email: string;
  /** Error message */
  error: string;
  /** import order lines */
  lines: ImportOrderLine[]
  /** Storage key for production file */
  productionPdf: string;

  constructor(importOrder?: Partial<ImportOrder>) {
    this.id = importOrder?.id ?? '';
    this.reference = importOrder?.reference ?? '';
    this.date = importOrder?.date ?? new Date();
    this.processed = importOrder?.processed ?? false;
    this.isRunning = importOrder?.isRunning ?? true;
    this.pushIsRunning = importOrder?.pushIsRunning ?? false;
    this.type = importOrder?.type ?? '';
    this.customerId = importOrder?.customerId ?? 0;
    this.company = importOrder?.company ?? '';
    this.address = importOrder?.address ?? '';
    this.addressNumber = importOrder?.addressNumber ?? '';
    this.contactName = importOrder?.contactName ?? '';
    this.zipcode = importOrder?.zipcode ?? '';
    this.city = importOrder?.city ?? '';
    this.email = importOrder?.email ?? '';
    this.error = importOrder?.error ?? '';
    this.lines = importOrder?.lines ?? [];
    this.productionPdf = importOrder?.productionPdf ?? '';
  }


  static converter: FirestoreDataConverter<ImportOrder> = {
    toFirestore: (importOrder: ImportOrder) => ({
      id: importOrder.id,
      reference: importOrder.reference,
      date: importOrder.date,
      processed: importOrder.processed,
      isRunning: importOrder.isRunning,
      pushIsRunning: importOrder.pushIsRunning,
      type: importOrder.type,
      customerId: importOrder.customerId,
      company: importOrder.company,
      address: importOrder.address,
      addressNumber: importOrder.addressNumber,
      contactName: importOrder.contactName,
      zipcode: importOrder.zipcode,
      city: importOrder.city,
      email: importOrder.email,
      error: importOrder.error,
      lines: importOrder.lines,
      productionPdf: importOrder.productionPdf,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new ImportOrder({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null
      });
    }
  }
}
