export class NumberHelper {

    /**
     * Check if a number is positive and integer
     * @param num number
     */
    static isPositiveOrZero(num: number): boolean {
        return Number.isInteger(num) && num >= 0;
    }

    /**
     * Check if a number is positive and integer or 0
     * @param num number
     */
    static isPositiveInteger(num: number): boolean {
        return Number.isInteger(num) && num > 0;
    }
}
