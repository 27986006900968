import { FirestoreDataConverter } from '@angular/fire/firestore';
import { ImageModel } from './image.model';

export type ExpandableCommerceCategory = CommerceCategory & { level: number, expandable: boolean };

/**
 * Model for Commerce Category (Used for Commerce)
 */
export class CommerceCategory {
  id: string;
  shop: string;
  parent: string;
  name: string;
  description: string;
  image: ImageModel;
  icon: string;
  children: CommerceCategory[];

  constructor(data?: Partial<CommerceCategory>) {
    this.id = data?.id ?? '';
    this.shop = data?.shop ?? '';
    this.parent = data?.parent ?? '';
    this.name = data?.name ?? '';
    this.description = data?.description ?? '';
    this.image = data?.image ? new ImageModel(data.image) : new ImageModel();
    this.icon = data?.icon ?? '';
    this.children = data?.children ?? [];
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<CommerceCategory> = {
    toFirestore: (category: CommerceCategory) => {
      return {
        id: category.id,
        shop: category.shop,
        parent: category.parent,
        name: category.name,
        description: category.description,
        image: category.image ?
          {
            path: category.image.path,
            name: category.image.name,
            description: category.image.description,
          } :
          null,
        icon: category.icon,
      };
    },
    fromFirestore: (snapshot, options) => {
      return new CommerceCategory({
        ...snapshot.data(options),
        id: snapshot.id,
      });
    }
  };


  static nest(categories: CommerceCategory[]): CommerceCategory[] {
    categories = JSON.parse(JSON.stringify(categories));
    const roots: CommerceCategory[] = [];
    const temp: { [id: string]: CommerceCategory } = {};
    const pendingChildOf: { [id: string]: CommerceCategory[] } = {};

    for (const category of categories) {
      temp[category.id] = category;
      if (!category.parent) {
        roots.push(category);
      } else {
        if (temp[category.parent]) {
          temp[category.parent].children.push(category);
        } else {
          pendingChildOf[category.parent] = pendingChildOf[category.parent] ?? [];
          pendingChildOf[category.parent].push(category)
        }
      }
      if (pendingChildOf[category.id]) {
        for (const pendingChild of pendingChildOf[category.id]) {
          category.children.push(pendingChild);
        }
      }
    }
    return roots;
  }
}
