import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    /** The user is logged and is on auth page */
    if (this.authService.isLoggedIn && next.url.length) {
      return this.router.navigate(['']);
    }
    /** The user is not logged and is on home page */
    if (!this.authService.isLoggedIn && !next.url.length) {
      return this.router.navigate(['auth', 'login']);
    }
    return Promise.resolve(true);
  }
}
