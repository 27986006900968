import { inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CommerceShop } from "../models/commerce-shop.model";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query, snapToData, Unsubscribe,
  updateDoc,
  where
} from "@angular/fire/firestore";
import { ObjectHelper } from "../helpers/object.helper";
import { Smtp } from "../models/smtp.model";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { PaymentPlatform } from "../models/payment-platform.model";
import { CommerceProduct } from "../models/commerce-product.model";

@Injectable({
  providedIn: 'root',
})
export class ShopService {

  private firestore= inject(Firestore);
  private functions = inject(Functions);

  private shops$: BehaviorSubject<CommerceShop[]>;
  private unsubscribeShops: Unsubscribe;

  /**
   * Get the web shops
   * @returns The observable
   */
  shops(): BehaviorSubject<CommerceShop[]> {
    if (!this.shops$) {
      this.shops$ = new BehaviorSubject<CommerceShop[]>([]);
      const q = query(collection(this.firestore, 'shop')).withConverter(CommerceShop.converter);
      this.unsubscribeShops = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.shops$.next([]);
        }
        this.shops$.next(snapshot.docs.map(doc => doc.data()));
      });
    }
    return this.shops$;
  }

  /**
   * Get shops linked with the customer id
   * @param id The customer id
   * @returns Linked shops
   */
  async customerShops(id: string): Promise<CommerceShop[]> {
    const q = query(
      collection(this.firestore, 'shop'),
      where('customer', '==', id))
      .withConverter(CommerceProduct.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => new CommerceShop(snapToData(doc, { idField: 'id' })));
  }

  /**
   * Get the shop by id
   * @param id The shop id
   * @returns The shop
   */
  async shop(id: string): Promise<CommerceShop> {
    const ref = doc(this.firestore, 'shop', id)
      .withConverter(CommerceShop.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  /**
   * Create a new shop
   * @param shop the shop to create
   * @returns Created shop id
   */
  async addShop(shop: Partial<CommerceShop>): Promise<string> {
    const ref = collection(this.firestore, 'shop')
      .withConverter(CommerceShop.converter);
    return addDoc(ref, ObjectHelper.anonymize(shop))
      .then(ref => ref.id);
  }

  /**
   * Update the shop
   * @param shop The shop to update
   * @returns void
   */
  updateShop(shop: Partial<CommerceShop>): Promise<void> {
    const ref = doc(this.firestore, 'shop', shop.id).withConverter(CommerceShop.converter);
    return updateDoc(ref, ObjectHelper.anonymize(shop));
  }

  /**
   * Get the shop smtp settings
   * @param shopId The shop id
   * @returns Shop smtp config
   */
  async shopSmtp(shopId: string): Promise<Smtp> {
    const ref = doc(this.firestore, 'smtp', shopId).withConverter(Smtp.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return new Smtp({ id: shopId });
    }
    return docSnap.data();
  }

  /**
   * Update the shop smtp settings
   * @param smtp The smtp to update
   * @returns Updated Smtp
   */
  async updateSmtp(smtp: Smtp): Promise<Smtp> {
    return httpsCallable<Smtp, Smtp>(this.functions, 'update_smtp')(smtp)
      .then(result => result.data);
  }

  /**
   * Get the shop payment platforms
   * @param shopId The shop id
   * @returns shops payments platforms
   */
  async shopPaymentPlatforms(shopId: string): Promise<PaymentPlatform[]> {
    const q = query(
      collection(this.firestore, 'payment-platforms'),
      where('shop', '==', shopId)
    ).withConverter(PaymentPlatform.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => doc.data());
  }

  /**
   * Update the payment platforms
   * @param paymentPlatform The payment platform to update
   * @returns Updated payment platform
   */
  async updatePaymentPlatform(paymentPlatform: PaymentPlatform): Promise<PaymentPlatform> {
    return httpsCallable<PaymentPlatform, PaymentPlatform>(this.functions, 'update_payment_platform')(paymentPlatform)
      .then(result => result.data);
  }

  /**
   * Update the shop image
   * @param id The shop id
   * @param type The file type
   * @param url The image url
   * @returns void
   */
  updateShopImage(id: string, type: 'banner' | 'logo' | 'favIcon', url: string): Promise<void> {
    const ref = doc(this.firestore, 'shop', id).withConverter(CommerceShop.converter);
    return updateDoc(ref, { [type]: url });
  }

  /**
   * Send a test email
   * @param email Destination Email to test Smtp config
   * @param lang The language
   * @param shopId The shop id
   * @returns void
   */
  testSmtp(email: string, lang: string, shopId: string): Promise<void> {
    return httpsCallable<{
      email: string,
      lang: string,
      shopId: string
    }, void>(this.functions, 'shop_test_email')({ email, lang, shopId })
      .then(result => result.data);
  }

  /**
   * Unsubscribe from shops
   */
  unsubscribe(): void {
    if (this.unsubscribeShops) {
      this.unsubscribeShops();
      this.shops$ = null;
    }
  }
}
