import { FormControl, FormGroup } from "@angular/forms";

export type AddressForm = FormGroup<{
  name: FormControl<string>;
  company: FormControl<string>;
  street1: FormControl<string>;
  street2: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zip: FormControl<string>;
  country: FormControl<string>;
  phone: FormControl<string>;
  email: FormControl<string>;
}>

export class Address {
  name: string;
  company: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  email: string;

  constructor(data?: Partial<Address>) {
    this.name = data?.name ?? '';
    this.company = data?.company ?? '';
    this.street1 = data?.street1 ?? '';
    this.street2 = data?.street2 ?? '';
    this.city = data?.city ?? '';
    this.state = data?.state ?? '';
    this.zip = data?.zip ?? '';
    this.country = data?.country ?? '';
    this.phone = data?.phone ?? '';
    this.email = data?.email ?? '';
  }
}
