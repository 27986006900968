import {FirestoreDataConverter} from '@angular/fire/firestore';

export class FileTask {

  id?: string;
  orderId: string;
  processed: boolean;
  withError: boolean;
  inProgress: boolean;
  imported: boolean;
  message?: string;
  date: Date

  constructor(task?: Partial<FileTask>) {
    this.id = task?.id ?? '';
    this.orderId = task?.orderId ?? '';
    this.processed = task?.processed ?? false;
    this.withError = task?.withError ?? false;
    this.inProgress = task?.inProgress ?? false;
    this.message = task?.message ?? '';
    this.date = task?.date ?? null;
  }

  static converter: FirestoreDataConverter<FileTask> = {
    toFirestore: fileTask => ({
      orderId: fileTask.orderId,
      processed: fileTask.processed,
      withError: fileTask.withError,
      inProgress: fileTask.inProgress,
      date: fileTask.date,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new FileTask({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null
      })
    }
  };
}
