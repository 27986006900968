import {Injectable} from '@angular/core';
import {BuildDetails} from "../models/build-details.model";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {lastValueFrom, switchMap, timer} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {UpdateAppComponent} from "../components/update-app/update-app.component";
import {first} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class UpToDateService {
  private buildDetails: BuildDetails;

  constructor(private http: HttpClient, private dialog: MatDialog) {
    this.fetch()
      .then(buildDetails => this.buildDetails = buildDetails)
      .then(() => this.poll());
  }

  private fetch(): Promise<BuildDetails> {
    return lastValueFrom(this.http.get<BuildDetails>('assets/build-details.json', {headers: new HttpHeaders({'Cache-Control': 'no-cache'})}));
  }

  private poll() {
    const pollInterval = 60000;
    timer(0, pollInterval)
      .pipe(
        switchMap(() => this.fetch()),
        first(buildDetails => this.buildDetails.timestamp !== buildDetails.timestamp)
      )
      .subscribe(() => this.dialog.open(UpdateAppComponent, {closeOnNavigation: false, disableClose: true}));
  }
}