import { FirestoreDataConverter } from '@angular/fire/firestore';

/**
 * Model for Commerce Product Variation (Used for Commerce)
 */
export class CommercePriceGrid {
  id: string;
  variationId: string;
  name: string;
  prices: { from: number, price: number }[];
  autoRefresh: boolean;
  quantityMin: number | null;
  quantityMax: number | null;
  fixedCost: number | null;
  customers: [string?];

  constructor(data?: Partial<CommercePriceGrid>) {
    this.id = data?.id ?? '';
    this.variationId = data?.variationId ?? '';
    this.name = data?.name ?? '';
    this.autoRefresh = data?.autoRefresh ?? false;
    this.quantityMin = data?.quantityMin ?? 0;
    this.quantityMax = data?.quantityMax ?? 0;
    this.fixedCost = data?.fixedCost ?? 0;
    this.prices = data?.prices ?? [];
    this.customers = data?.customers ?? [];
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<CommercePriceGrid> = {
    toFirestore: (price: CommercePriceGrid) => {
      return {
        id: price.id,
        variationId: price.variationId,
        name: price.name,
        autoRefresh: price.autoRefresh,
        prices: price.prices,
        customers: price.customers,
        quantityMin: Number.isInteger(price.quantityMin) ? Number(price.quantityMin) : 0,
        quantityMax: Number.isInteger(price.quantityMax) ? Number(price.quantityMax) : 0,
        fixedCost: Number.isNaN(price.fixedCost) ? 0 : Number(price.fixedCost)
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new CommercePriceGrid({
        ...data,
        id: snapshot.id,
      });
    }
  };
}
