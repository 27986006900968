import {FirestoreDataConverter} from '@angular/fire/firestore';

/**
 * Model for convert task
 */
export class ConvertTask {
  date: Date;
  extension: 'JPG'|'PNG';
  path: string;
  message: string;
  processed: boolean;
  withError: boolean;

  constructor(task?: Partial<ConvertTask>) {
    this.date = task.date ?? new Date();
    this.extension = task?.extension ?? 'JPG';
    this.path = task?.path ?? '';
    this.message = task?.message ?? '';
    this.processed = task?.processed ?? false;
    this.withError = task?.withError ?? false;
  }

  /**
   * Converter used to convert a ConvertTask from firebase
   */
  static converter: FirestoreDataConverter<ConvertTask> = {
    toFirestore: (task: ConvertTask) => ({
      date: task.date,
      extension: task.extension,
      path: task.path,
      message: task.message,
      processed: task.processed,
      withError: task.withError
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new ConvertTask({
        ...data,
        date: data.date ? new Date(data.date.seconds * 1000) : null,
      });
    }
  };
}
