import { Component, inject } from '@angular/core';
import { User } from "../../models/user.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { UploadService } from "../../services/upload.service";
import { RolesEnum } from "../../enums/roles.enum";
import { UserService } from "../../services/user.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent {
  public authService = inject(AuthService);
  private snackBar = inject(MatSnackBar);
  private translate = inject(TranslateService);
  private uploadService = inject(UploadService);
  private userService = inject(UserService);
  protected readonly RolesEnum = RolesEnum;

  public accountForm: FormGroup;
  public hideForm = true;
  public progress = false;
  public disable = false;
  private images = {};

  /**
   * Initialize profile form content
   */
  private initAccountData(): void {
    this.accountForm = new FormGroup({
      firstName: new FormControl(this.authService.currentUser.firstName, [Validators.required]),
      lastName: new FormControl(this.authService.currentUser.lastName, [Validators.required]),
      company: new FormControl(this.authService.currentUser.company, [Validators.required]),
      productionNotification: new FormControl(this.authService.currentUser.productionNotification, [Validators.required])
    });
  }

  /**
   * Save user information to the database
   * @param updatedUser The user with updated data
   * @param event Click event
   */
  public async update(updatedUser: User, event: Event): Promise<void> {
    event.stopPropagation();
    try {
      this.disable = true;
      this.progress = true;
      const user = this.authService.currentUser;
      user.firstName = updatedUser.firstName;
      user.lastName = updatedUser.lastName;
      user.company = updatedUser.company;
      user.productionNotification = updatedUser.productionNotification;
      await this.userService.saveUser(user.id, {
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        company: updatedUser.company,
        productionNotification: updatedUser.productionNotification,
      });
      this.snackBar.open(this.translate.instant('account.success.edit'), 'x');
    } catch (err) {
      console.error(err);
      this.snackBar.open(this.translate.instant('account.fail.edit'), 'x');
    } finally {
      this.hideForm = true;
      this.progress = false;
      this.disable = false;
    }
  }

  /**
   * Display form to edit user information
   */
  public edit(event: Event): void {
    event.stopPropagation();
    this.hideForm = false;
    this.initAccountData();
  }

  /**
   * Hide the form to edit user without information saving
   */
  public cancel(event: Event): void {
    event.stopPropagation();
    this.hideForm = true;
  }

  /**
   * Upload a profile image for the user and save the URL to the database
   */
  public async upload(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    const file = files[0];
    const user = this.authService.currentUser;
    try {
      await this.cleanOldProfileImage(user);
    } catch (err) {
      console.error(err);
      this.snackBar.open(this.translate.instant('account.fail.upload'), 'x');
    }
    this.progress = true;
    const extension = file.name.split('.').pop();
    const path = 'profile/' + user.id + '/profile.' + extension;

    const task = this.uploadService.uploadFile(file, path);
    task
      .then(() =>
        this.uploadService.getFileUrl(path)
          .then(fileUrl => {
            this.images[user.id] = {image: fileUrl};
            user.photoURL = fileUrl;
            return this.userService.saveUser(user.id, {photoURL: fileUrl});
          })
          .then(() => this.snackBar.open(this.translate.instant('account.success.upload'), 'x')))
      .catch(err => {
        console.error(err);
        this.snackBar.open(this.translate.instant('account.fail.upload'), 'x');
      })
      .finally(() => this.progress = false);
  }

  /**
   * Remove existing user profile image
   * @param user The connected user
   */
  private async cleanOldProfileImage(user: User): Promise<void> {
    try {
      if (user.photoURL) {
        const oldFile = user.photoURL;
        await this.uploadService.removeFile(oldFile);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
