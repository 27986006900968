import { UUID } from 'angular2-uuid';

export class Quantity {
  manual: boolean;
  value: number;

  constructor(data?: Partial<Quantity>) {
    this.manual = data?.manual ?? false;
    this.value = data?.value ?? 0;
  }

  get description(): string {
    return this.manual ?
      'configuration.quantities.manual.label' :
      this.value.toString() ?? '';
  }
}

export class Overprint {
  id: string;
  description: string;
  value: string;
  type: OverprintTypeEnum;

  constructor(data?: Partial<Overprint>) {
    this.id = data?.id ?? UUID.UUID().toString();
    this.description = data?.description ?? '';
    this.value = data?.value ?? '';
    this.type = data?.type ?? OverprintTypeEnum.GENERIC;
  }
}

export enum OverprintTypeEnum {
  GENERIC = 'generic'
}
