import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RolesEnum } from 'src/app/shared/enums/roles.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrganizationService } from "../services/organization.service";

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate {
  private organizationService = inject(OrganizationService);

  constructor(
    public authService: AuthService,
    public router: Router
  ) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Promise<boolean> {
    if (this.authService.isAnonymous) {
      return this.router.navigate(['auth', 'login']);
    }
    const organizationId = state.root.firstChild.params['organizationId'];
    if (!organizationId) {
      return this.router.navigate(['']);
    }
    const organization = this.organizationService.currentOrganization.id ?
      this.organizationService.currentOrganization :
      await this.organizationService.getByOid(organizationId);
    if (!organization) {
      return this.router.navigate(['']);
    }
    try {
      await this.organizationService.waitOrganization();
    } catch (err) {
      return this.router.navigate([''])
    }
    // TODO : Replace it by firestore rules
    if (!this.organizationService.organization$.value.users.includes(this.authService.getUid) &&
          !this.organizationService.organization$.value.administrators.includes(this.authService.getUid)) {
      return this.router.navigate(['']);
    }
    const requiredRole = next.data.require as RolesEnum;
    if (!requiredRole) {
      return true;
    }
    return this.authService.hasRole(requiredRole) || this.router.navigate([''])
  }
}
