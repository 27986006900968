import { FirestoreDataConverter } from '@angular/fire/firestore';
import { UnitTypeEnum } from '../enums/unit-type.enum';

/**
 * Model for Commerce Product Variation (Used for Commerce)
 */
export class CommerceVariation {
  id: string;
  name: string;
  erpId: string;
  configuration: string;
  material: string | null;
  printType: string | null;
  format: string | null;
  finish: string | null;
  cutting: string | null;
  drilling: string | null;
  binding: string | null;
  folding: string | null;
  coverMaterial: string | null;
  script: string;
  unit: UnitTypeEnum;
  dynamic: boolean;
  pageNumber: number;

  constructor(data?: Partial<CommerceVariation>) {
    this.id = data?.id ?? '';
    this.name = data?.name ?? '';
    this.erpId = data?.erpId ?? '';
    this.configuration = data?.configuration ?? '';
    this.material = data?.material ?? null;
    this.printType = data?.printType ?? null;
    this.format = data?.format ?? null;
    this.finish = data?.finish ?? null;
    this.cutting = data?.cutting ?? null;
    this.drilling = data?.drilling ?? null;
    this.binding = data?.binding ?? null;
    this.folding = data?.folding ?? null;
    this.coverMaterial = data?.coverMaterial ?? null;
    this.script = data?.script ?? '';
    this.unit = data?.unit ?? UnitTypeEnum.Unit;
    this.dynamic = data?.dynamic ?? false;
    this.pageNumber = data?.pageNumber ?? 0;
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<CommerceVariation> = {
    toFirestore: (variation: CommerceVariation) => {
      return {
        id: variation.id,
        name: variation.name,
        erpId: variation.erpId,
        configuration: variation.configuration,
        material: variation.material,
        printType: variation.printType,
        format: variation.format,
        finish: variation.finish,
        cutting: variation.cutting,
        drilling: variation.drilling,
        binding: variation.binding,
        folding: variation.folding,
        coverMaterial: variation.coverMaterial,
        script: variation.script,
        unit: variation.unit,
        dynamic: variation.dynamic,
        pageNumber: variation.pageNumber,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new CommerceVariation({
        ...data,
        id: snapshot.id,
      });
    }
  };
}
