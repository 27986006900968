import { FirestoreDataConverter } from "@angular/fire/firestore";
import { PlanEnum } from "../enums/plan.enum";

/**
 * Organization class
 */
export class Organization {
  id: string;
  oid: string;
  name: string;
  description: string;
  administrators: string[];
  users: string[];
  plan: PlanEnum;

  constructor(organization?: Partial<Organization>) {
    this.id = organization?.id ?? '';
    this.oid = organization?.oid ?? '';
    this.description = organization?.description ?? '';
    this.name = organization?.name ?? '';
    this.administrators = organization?.administrators ?? [];
    this.users = organization?.users ?? [];
    this.plan = organization?.plan ?? PlanEnum.FREE;
  }

  /**
   * Converter used to convert an organization from firebase
   */
  static converter: FirestoreDataConverter<Organization> = {
    toFirestore: (organization: Organization) => ({
      id: organization.id ,
      oid: organization.oid,
      description: organization.description,
      name: organization.name,
      administrators: organization.administrators,
      users: organization.users,
      plan: organization.plan,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Organization({
        ...data,
        id: snapshot.id,
      });
    }
  }
}
