/**
 * Enumeration to list all possible states for a quotation
 */
export enum QuotationState {
  NEW = 'new',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  CANCELED = 'canceled',
}
