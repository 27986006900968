import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import {Auth} from '@angular/fire/auth';
import {from, Observable} from 'rxjs';
import {environment} from "../../../environments/environment";
import {switchMap} from "rxjs/operators";

@Injectable()
export class FirebaseAuthInterceptor implements HttpInterceptor {

  constructor(private auth: Auth) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!req.url.startsWith(environment.functionsUrlEuropeWest1)) {
      return next.handle(req);
    }

    return from(this.auth.currentUser.getIdToken())
      .pipe(
        switchMap(token => {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
          return next.handle(authReq)
        })
      );
  }
}
