import { Address } from './address.model';
import { FirestoreDataConverter } from '@angular/fire/firestore';
import { ObjectHelper } from '../helpers/object.helper';
import { InvoicingEngineEnum } from "../enums/invoicing-mode.enum";

export class BusinessUnitModel {
  id: string;
  name: string;
  erpId: string;
  logo: string;
  invoiceNote: string;
  legalInformation: string;
  address: Address;
  default: boolean
  invoicingEngine: InvoicingEngineEnum;
  organizationId: string;

  constructor(businessUnit?: Partial<BusinessUnitModel>) {
    this.id = businessUnit?.id ?? '';
    this.name = businessUnit?.name ?? '';
    this.erpId = businessUnit?.erpId ?? '';
    this.logo = businessUnit?.logo ?? '';
    this.invoiceNote = businessUnit?.invoiceNote ?? '';
    this.legalInformation = businessUnit?.legalInformation ?? '';
    this.address = businessUnit?.address ?? new Address();
    this.default = businessUnit?.default ?? false;
    this.invoicingEngine = businessUnit?.invoicingEngine ?? InvoicingEngineEnum.NONE;
    this.organizationId = businessUnit?.organizationId ?? '';
  }

  /**
   * Converter used to convert a site from firebase
   */
  static readonly converter: FirestoreDataConverter<BusinessUnitModel> = {
    toFirestore: (businessUnit: BusinessUnitModel) => ({
      id: businessUnit.id,
      name: businessUnit.name,
      erpId: businessUnit.erpId,
      default: businessUnit.default,
      logo: businessUnit.logo,
      invoiceNote: businessUnit.invoiceNote,
      legalInformation: businessUnit.legalInformation,
      address: ObjectHelper.anonymize(businessUnit.address),
      invoicingEngine: businessUnit.invoicingEngine,
      organizationId: businessUnit.organizationId,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new BusinessUnitModel({
        ...data,
        id: snapshot.id,
        address: new Address(data.address)
      });
    }
  }
}
