import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Option} from '../models/option.model';
import {StyleManagerService} from './style-manager.service';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public smallLayout = false;

  constructor(
    private http: HttpClient,
    private styleManager: StyleManagerService,
    private responsive: BreakpointObserver
  ) {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .subscribe(result => {
        this.smallLayout = result.matches;
      });
  }

  public getThemeOptions(): Observable<Array<Option>> {
    return this.http.get<Array<Option>>('assets/theme/options.json');
  }

  public setTheme(themeToSet: string) {
    this.styleManager.setStyle(
      'theme',
      `assets/theme/${themeToSet}.css`
    );
  }
}
