import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';

export class Folding {
  id: string;
  erpId: string;
  description: string;

  constructor(data?: Partial<Folding>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
  }

  static toFirestore(folding: Folding): Folding {
    return {
      id: folding.id,
      erpId: folding.erpId,
      description: folding.description,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot): Folding {
    return new Folding(snapToData(snapshot, { idField: 'id' }));
  }
}
