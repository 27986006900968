export class ProductOrderFile {
  path: string;
  height: number;
  width: number;
  isValid: boolean;
  shapeId: string;
  name: string;

  constructor(file?: Partial<ProductOrderFile>) {
    this.path = file?.path ?? '';
    this.height = file?.height ?? 0;
    this.width = file?.width ?? 0;
    this.isValid = file?.isValid ?? false;
    this.shapeId = file?.shapeId ?? '';
    this.name = file?.name ?? '';
  }
}
