import {FirestoreDataConverter} from '@angular/fire/firestore';
import { Settings } from './settings.base';
import {SettingsEnum} from '../enums/settings.enum';
import {ObjectHelper} from "../helpers/object.helper";

export class CallasSettings extends Settings {
  converter: FirestoreDataConverter<CallasSettings> = {
    toFirestore: settings => {
      const obj = ObjectHelper.anonymize(settings);
      delete obj.converter;
      delete obj.type;
      return obj;},
    fromFirestore: (snapshot, options) => new CallasSettings({...snapshot.data(options), id: snapshot.id})
  };
  type = SettingsEnum.CALLAS;

  /** Folder for client files */
  customerFolder: string;
  /** Folder for BAT files */
  batFolder: string;
  /** Folder for PAO files */
  paoFolder: string;
  /** Folder for production files */
  productionFolder: string;
  /** List of scripts which will be blocked automatically to state To be process after callas processing */
  warnScripts: string[];
  /** The directory where the workflow is located */
  workflowDir: string;
  /** The SAMBA share where the workflow is located */
  workflowSmbShare: string;
  /** Template for order path */
  workflowPathTemplate: string;

  constructor(data?: Partial<CallasSettings>) {
    super(data);
    this.customerFolder = data?.customerFolder ?? '';
    this.batFolder = data?.batFolder ?? '';
    this.paoFolder = data?.paoFolder ?? '';
    this.productionFolder = data?.productionFolder ?? '';
    this.warnScripts = data?.warnScripts ?? [];
    this.workflowDir = data?.workflowDir ?? '';
    this.workflowSmbShare = data?.workflowSmbShare ?? '';
    this.workflowPathTemplate = data?.workflowPathTemplate ?? '';
  }
}
