import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';

export class Printing {
  id: string;
  erpId: string;
  description: string;
  sides: number;

  constructor(data?: Partial<Printing>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.sides = data?.sides ?? 1;
  }

  static toFirestore(printing: Printing) {
    return {
      id: printing.id,
      erpId: printing.erpId,
      description: printing.description,
      sides: printing.sides,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Printing(snapToData(snapshot, {idField: 'id'}));
  }
}