import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'error',
  pure: true,
})
export class ErrorPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  public transform(error: any): string {
    const oktoError = error?.error || error;
    if (oktoError?.code) {
      const codeTranslated = this.translate.instant(oktoError.code, oktoError.data ?? {error: oktoError.displayText});
      if (codeTranslated !== oktoError.code) {
        return codeTranslated;
      }
    }
    if (oktoError?.displayText) {
      return oktoError.displayText;
    }
    if (oktoError?.message) {
      return this.translate.instant(oktoError.message);
    }
    return JSON.stringify(oktoError);
  }
}
