/**
 * Enumeration to list all possible states for an order
 */
export enum OrderState  {
    NEW_ORDER = 0,
    WAIT_FILES = 1,
    PROCESSING = 2,
    WAIT_BAT = 3,
    BAT_REFUSED = 8,
    BAT_ACCEPTED = 7,
    PRODUCTION = 4,
    SHIPPED = 9,
    INVOICING = 10,
    FINISHED = 99,
}
