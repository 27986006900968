import { FirestoreDataConverter } from '@angular/fire/firestore';

export class Smtp {
  id: string;
  host: string;
  port: number;
  user: string;
  sender: string;
  password: string;

  constructor(data?: Partial<Smtp>) {
    this.id = data?.id ?? '';
    this.host = data?.host ?? '';
    this.port = data?.port ?? 0;
    this.user = data?.user ?? '';
    this.sender = data?.sender ?? '';
    this.password = data?.password ?? '';
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<Smtp> = {
    toFirestore: (smtp: Smtp) => {
      return {
        id: smtp.id,
        host: smtp.host,
        port: smtp.port,
        user: smtp.user,
        password: smtp.password,
        sender: smtp.sender
      };
    },
    fromFirestore: (snapshot, options) => {
      return new Smtp({
        ...snapshot.data(options),
        id: snapshot.id,
      });
    }
  };
}
