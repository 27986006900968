import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Option } from '../shared/models/option.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent {
    @Input() options: Array<Option>;
    @Output() themeChange: EventEmitter<string> = new EventEmitter<string>();

    changeTheme(themeToSet) {
        this.themeChange.emit(themeToSet);
    }
}
