/**
 * Enumeration to list all possible states for a quotation
 */
export enum Notification {
  NONE_SENT = -1,
  SEND_FIRST = 0,
  SEND_SECOND = 1,
  SEND_LATEST = 2,
  ALL_SENT = 3,
}
