import { Injectable } from '@angular/core';
import { INGXLoggerMetadata, INGXLoggerMonitor, NgxLoggerLevel } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoggerComponent } from '../../logger/logger.component';

export interface DialogData {
  message: string;
}

@Injectable({
  providedIn: 'root'
})

export class UxLogger implements INGXLoggerMonitor {

  public constructor(public translate: TranslateService,
                     public snackBar: MatSnackBar,
                     public dialog: MatDialog) {
  }

  onLog(logObject: INGXLoggerMetadata): void {

    switch (logObject.level) {

      case NgxLoggerLevel.INFO:
      case NgxLoggerLevel.ERROR:
        this.dialog.open(LoggerComponent, {
          data: {message: this.translate.instant(logObject.message)}
        });
        break;
      case NgxLoggerLevel.FATAL:
        break;
    }
  }
}
