<div class="px-16 py-8" *ngIf="authService.isLoggedIn">
  <div class="flex flex-col items-center mb-2">
    <div class="relative">
      <img *ngIf="authService.currentUser.photoURL" src="{{authService.currentUser.photoURL}}"
           class="shadow-xl rounded-full h-40 w-40 object-contain" alt="user profile image">
      <div *ngIf="!authService.currentUser.photoURL && authService.currentUser.displayName"
           class="shadow-xl font-bold text-gray-700 rounded-full bg-white flex items-center justify-center text-4xl h-40 w-40">
        {{authService.currentUser.displayName.substring(0, 1)}}
      </div>
      <button mat-mini-fab color="primary" *ngIf="!hideForm"
              class="absolute right-0 bottom-1" (click)="$event.stopPropagation(); profileImage.click()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <input #profileImage type="file" accept="image/*" class="hidden" (change)="upload($event)"
           (click)="$event.stopPropagation();">
    <div class="mt-4">
      <h1 class="m-0 text-center">{{authService.currentUser.firstName + ' ' + authService.currentUser.lastName}}</h1>
      <h4 class="text-sm text-center">{{authService.currentUser.email}}</h4>
    </div>
  </div>
  <div class="flex flex-row gap-4">
    <button mat-stroked-button (click)="authService.signOut()" *ngIf="hideForm">
      <mat-icon matListItemIcon>exit_to_app</mat-icon>
      {{ 'DASHBOARD.LOGOUT' | translate }}
    </button>
    <button mat-stroked-button (click)="edit($event)" *ngIf="hideForm">
      {{ 'account.edit.profile' | translate }}
    </button>
  </div>
  <form [formGroup]="accountForm" *ngIf="!hideForm" (click)="$event.stopPropagation()">
    <div class="flex flex-col gap-4 w-96">
      <mat-form-field>
        <mat-label>{{ 'ACCOUNT.FIRST_NAME' | translate }}</mat-label>
        <input matInput type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
               formControlName="firstName">
        <mat-error *ngIf="accountForm.controls.firstName.hasError('required')">
          {{ 'common.required.field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'name' | translate }}</mat-label>
        <input matInput type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
               formControlName="lastName">
        <mat-error *ngIf="accountForm.controls.lastName.hasError('required')">
          {{ 'common.required.field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'ACCOUNT.COMPANY' | translate }}</mat-label>
        <input matInput type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
               formControlName="company">
        <mat-error *ngIf="accountForm.controls.company.hasError('required')">
          {{ 'common.required.field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-checkbox *ngIf="authService.hasRole(RolesEnum.ORDER_WRITE)" formControlName="productionNotification">
        {{ 'utilityAgentNotifications' | translate }}
      </mat-checkbox>
      <div class="flex flex-row gap-4 place-content-between pt-4">
        <button mat-stroked-button (click)="cancel($event)" [disabled]="disable">
          {{ 'common.cancel.edit' | translate }}
        </button>
        <button mat-raised-button (click)="update(accountForm.value, $event)" color="primary"
                [disabled]="accountForm.invalid">
          {{ 'ACCOUNT.UPDATE_PROFILE' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
