import { FirestoreDataConverter } from '@angular/fire/firestore';

export class SiteModel {
  id: string;
  name: string;
  erpId: string;
  default: boolean

  constructor(site?: Partial<SiteModel>) {
    this.id = site?.id ?? '';
    this.name = site?.name ?? '';
    this.erpId = site?.erpId ?? '';
    this.default = site?.default ?? false;
  }

  /**
   * Converter used to convert a site from firebase
   */
  static converter: FirestoreDataConverter<SiteModel> = {
    toFirestore: (site: SiteModel) => ({
      id: site.id,
      name: site.name,
      erpId: site.erpId,
      default: site.default,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new SiteModel({
        ...data,
        id: snapshot.id,
      });
    }
  }
}
