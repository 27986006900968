import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Order } from '../models/order.model';
import { RolesEnum } from '../enums/roles.enum';
import { OrderState } from '../enums/order-state.enum';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'orderState',
  pure: true,
})
export class OrderStatePipe implements PipeTransform {

  constructor(private translate: TranslateService, private authService: AuthService) {
  }

  public transform(order: Order): string {
    if (order.canceled) {
      return this.translate.instant('ORDERS.ORDER_CANCELED');
    }
    const isAdmin = this.authService.hasRole(RolesEnum.ORDER_WRITE);
    const type = isAdmin ? 'admin' : 'customer';
    switch (order.state) {
    case OrderState.NEW_ORDER:
      return this.translate.instant(`order.admin.state0`);
    case OrderState.WAIT_FILES:
      return this.translate.instant(`order.admin.state1`);
    case OrderState.PROCESSING:
      return this.translate.instant(`order.${type}.state2`);
    case OrderState.WAIT_BAT:
      return this.translate.instant(`order.admin.state3`);
    case OrderState.PRODUCTION:
      return this.translate.instant(`order.admin.state4`);
    case OrderState.BAT_ACCEPTED:
      return this.translate.instant(`order.admin.state7`);
    case OrderState.BAT_REFUSED:
      return this.translate.instant(`order.admin.state8`);
    case OrderState.SHIPPED:
      return this.translate.instant(`order.admin.state9`);
    case OrderState.INVOICING:
      return this.translate.instant(`order.admin.state10`);
    case OrderState.FINISHED:
      return this.translate.instant(`order.admin.state99`);
    }
  }
}
