export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyB2cX-PUJIgc4xEpzpHaqaakk5L0ZoL_tk',
    authDomain: 'okto-0.firebaseapp.com',
    databaseURL: 'https://okto-0.firebaseio.com',
    projectId: 'okto-0',
    storageBucket: 'okto-0.appspot.com',
    messagingSenderId: '154226038786',
    appId: '1:154226038786:web:99b41a3d013014c8e6af1b',
    measurementId: 'G-STE53LVLQD'
  },
  captchaKey: '6LcqgzUiAAAAAOSwaiRC-u8aNbGCM7q-tpOVimE6',
  functionsUrlEuropeWest1: 'https://europe-west1-okto-0.cloudfunctions.net/',
  adobeKey: '6b1cd5e5ea6146c1ae354ccce9557235',
  plugins: []
};
