import { FormControl, FormGroup } from '@angular/forms';

export type ImageModelForm = FormGroup<{
  path: FormControl<string>;
  name: FormControl<string>;
  thumb: FormControl<string>;
  description: FormControl<string>;
  default: FormControl<boolean>;
  file: FormControl<File>;
}>

export class ImageModel {
  public path: string;
  public thumb: string;
  public name: string;
  public description: string;
  public default: boolean;

  constructor(data?: Partial<ImageModel>) {
    this.path = data?.path ?? '';
    this.name = data?.name ?? '';
    this.thumb = data?.thumb ?? '';
    this.description = data?.description ?? '';
    this.default = data?.default ?? false;
  }
}
