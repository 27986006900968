import { inject, Injectable, Injector } from '@angular/core';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { FirebaseErrors } from '../helpers/firebaseErrors.helper';
import { RolesEnum } from '../enums/roles.enum';
import {
  linkWithCredential,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  Auth,
  UserCredential,
  EmailAuthProvider,
  onIdTokenChanged
} from '@angular/fire/auth';
import { ImportService } from "./import.service";
import { OrderService } from "./order.service";
import { SettingsService } from "./settings.service";
import { CommerceService } from "./commerce.service";
import { Functions, httpsCallable } from '@angular/fire/functions';
import { QuotationService } from './quotation.service';
import { OrganizationService } from "./organization.service";
import { UserService } from "./user.service";
import { ProductService } from "./product.service";
import { ShopService } from "./shop.service";

@Injectable({
  providedIn: 'root',
})
/**
 * Auth service
 */
export class AuthService {
  private _currentUser: User;
  private organizationService = inject(OrganizationService);
  private userService = inject(UserService);

  public get currentUser(): User {
    return this._currentUser ?? new User();
  }

  public get isOrganizationUser(): boolean {
    return this.organizationService.currentOrganization.users.includes(this.auth.currentUser.uid)
      || this.organizationService.currentOrganization.administrators.includes(this.auth.currentUser.uid);
  }

  public get isAnonymous(): boolean {
    return this.auth.currentUser?.isAnonymous ?? true;
  }

  public get isVerified(): boolean {
    return this.auth.currentUser?.emailVerified ?? true;
  }

  public get getUid(): string {
    return this.auth.currentUser?.uid ?? '';
  }

  /**
   * Check if a user is logged in
   * @returns True if the user is
   */
  public get isLoggedIn(): boolean {
    if (!this.auth) {
      return false;
    }
    return !!(this.auth.currentUser && !this.auth.currentUser.isAnonymous);
  }

  constructor(public auth: Auth,
              private router: Router,
              private functions: Functions,
              private injector: Injector) {
    this.initSubscribeToAuth();
  }

  public async waitAuth(): Promise<void> {
    while (!this._currentUser) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  /**
   * This listen to all users change, and not only to the connected user
   */
  private initSubscribeToAuth(): void {
    onIdTokenChanged(this.auth, authUser => {
      if (!authUser) {
        console.log('no user try to login anonymous')
        return signInAnonymously(this.auth);
      }
      if (authUser.isAnonymous) {
        console.log('User is signed anonymously')
        this._currentUser = new User();
        return;
      }
      console.log('User is signed')
      /**
       * A user has been created and automatically logged in
       * We only need to detect sign in from signed out state and can ignore if currentUser is set
       */
      this.userService.getUserByUid(authUser.uid).then(user => {
        this._currentUser = user ?? new User();
      }).catch(err => console.error(err));
    })
  }

  /**
   * Login with login/password
   * @param email The user email
   * @param password The user password
   */
  public async signIn(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (err) {
      console.error(err);
      const message = FirebaseErrors.Parse(err.code);
      throw new Error(message);
    }
  }

  /**
   * Create a new account
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   * @param lang
   * @param uid The anonymous user id
   * @param redirect The redirect URI
   */
  public async createAccount(email: string, password: string, firstName: string, lastName: string, lang: string,
    uid: string, redirect: string): Promise<void> {
    const res = await httpsCallable<{
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      lang: string;
      shopId: string;
      uid: string;
      redirect: string;
    }, void>(this.functions, 'shop_sign_up')(
      { email, password, firstName, lastName, lang, shopId: 'okto', uid, redirect });
    return res.data;
  }

  /**
   * Transform an anonymous user to a real user
   * @param email The user email
   * @param password The user password
   */
  public async linkAccount(email: string, password: string): Promise<UserCredential> {
    const credential = EmailAuthProvider.credential(email, password)
    if (this.auth.currentUser) {
      return await linkWithCredential(this.auth.currentUser, credential)
    }
    throw new Error();
  }

  /**
   * Email to confirm email address
   * @param email User email
   * @param lang User language
   */
  public async confirmationLink(email: string, lang: string): Promise<void> {
    return httpsCallable<{ email: string, shopId: string, lang: string }, void>
    (this.functions, 'shop_confirmation_link')({ email, shopId: 'okto', lang: lang })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        const message = FirebaseErrors.Parse(err.code);
        throw new Error(message);
      });
  }

  /**
   * Email to reset password
   * @param email User email
   * @param lang User language
   */
  public async resetPassword(email: string, lang: string): Promise<void> {
    return httpsCallable<{ email: string, shopId: string, lang: string }, void>
    (this.functions, 'shop_reset_password')({ email, shopId: 'okto', lang: lang })
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        const message = FirebaseErrors.Parse(err.code);
        throw new Error(message);
      });
  }

  /**
   * Sign out the user
   * Remove it from the local storage
   * Redirect to the login page
   */
  public signOut(): void {
    this.unsubscribe();
    signOut(this.auth).then(() => {
      return this.router.navigate(['auth', 'login'])
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  /**
   * Check if the current user has the role
   * @param role
   */
  public hasRole(role: RolesEnum): boolean {
    return this._currentUser?.roles.includes(role);
  }

  /**
   * Unsubscribe from all data
   */
  private unsubscribe(): void {
    this.injector.get(QuotationService).unsubscribe();
    this.injector.get(ImportService).unsubscribe();
    this.injector.get(OrderService).unsubscribe();
    this.injector.get(SettingsService).unsubscribe();
    this.injector.get(CommerceService).unsubscribe();
    this.injector.get(ProductService).unsubscribe();
    this.injector.get(ShopService).unsubscribe();
    this.organizationService.unsubscribe();
  }

  /**
   * Refresh connected user
   * @returns void
   */
  refreshUser(): Promise<void> {
    return this.auth?.currentUser?.reload();
  }
}
