import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './notfound/notfound.component';
import { AuthGuard } from "./shared/guards/auth.guard";
import { VerifiedGuard } from "./shared/guards/verified.guard";
import { RoleGuard } from "./shared/guards/role.guard";
import { RolesEnum } from "./shared/enums/roles.enum";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: ':organizationId',
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
    canActivate: [VerifiedGuard]
  },
  {
    path: ':organizationId/shipment',
    loadChildren: () => import('./shipment/shipment.module')
      .then(m => m.ShipmentModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ },
  },
  {
    path: 'bat',
    loadChildren: () => import('./bat/bat.module')
      .then(m => m.BatModule),
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./subscribe/subscribe.module')
      .then(m => m.SubscribeModule),
  },
  {
    path: ':organizationId/bat',
    loadChildren: () => import('./bat/bat.module')
      .then(m => m.BatModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  {
    path: 'quotation',
    loadChildren: () => import('./quotation/quotation.module')
      .then(m => m.QuotationModule),
  },
  {
    path: ':organizationId/quotation',
    loadChildren: () => import('./quotation/quotation.module')
      .then(m => m.QuotationModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ },
  },
  {
    path: ':organizationId/order',
    loadChildren: () => import('./order/order.module')
      .then(m => m.OrderModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module')
      .then(m => m.TemplatesModule),
  },
  {
    path: ':organizationId/templates',
    loadChildren: () => import('./templates/templates.module')
      .then(m => m.TemplatesModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  {
    path: ':organizationId/transport',
    loadChildren: () => import('./transport/transport.module')
      .then(m => m.TransportModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ },
  },
  {
    path: ':organizationId/installation',
    loadChildren: () => import('./installation/installation.module')
      .then(m => m.InstallationModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  {
    path: ':organizationId/external-production',
    loadChildren: () => import('./external-production/external-production.module')
      .then(m => m.ExternalProductionModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  {
    path: ':organizationId/production',
    loadChildren: () => import('./production/production.module')
      .then(m => m.ProductionModule),
    canActivate: [RoleGuard],
    data: { require: RolesEnum.BASIC_READ }
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes /* , { enableTracing: true }*/, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})

/**
 * Main application routing module declaration
 */
export class AppRoutingModule {
}
