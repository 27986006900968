import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { UxLogger } from './shared/services/uxlogger.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './shared/services/data.service';
import { AuthService } from './shared/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { RolesEnum } from './shared/enums/roles.enum';
import { ThemeService } from './shared/services/theme.service';
import { Observable, Subscription } from 'rxjs';
import { Option } from './shared/models/option.model';
import packageInfo from '../../package.json';
import { OrderService } from './shared/services/order.service';
import { OrganizationService } from "./shared/services/organization.service";
import { environment } from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  options$: Observable<Array<Option>> = this.themeService.getThemeOptions();

  public smallLayout = false;
  public version: string = packageInfo.version;
  public menuOpened = true;
  public RolesEnum = RolesEnum;
  private subscriptions: Subscription[];
  public searchedValue = '';
  public organizationService = inject(OrganizationService);
  protected readonly environment = environment;

  constructor(
    public dataService: DataService,
    private translate: TranslateService,
    private logger: NGXLogger,
    public router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public authService: AuthService,
    private orderService: OrderService,
    private responsive: BreakpointObserver,
    private themeService: ThemeService) {}

  public ngOnInit(): void {
    this.menuOpened = !this.themeService.smallLayout;
    registerLocaleData(localeFr, 'fr');
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.logger.registerMonitor(new UxLogger(this.translate, this.snackBar, this.dialog));
    this.subscriptions = [
      this.responsive.observe(Breakpoints.XSmall)
        .subscribe(result => {
          this.smallLayout = result.matches;
        }),
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      })
    ];
    if (localStorage.getItem('theme')) {
      this.themeService.setTheme(localStorage.getItem('theme'));
    } else {
      this.themeService.setTheme('deeppurple-amber');
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public themeChangeHandler(themeToSet): void {
    this.themeService.setTheme(themeToSet);
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
      localStorage.removeItem('theme');
    }
    localStorage.setItem('theme', themeToSet);
  }

  public search(): void {
    this.orderService.getOrderByTransactionId(this.searchedValue)
      .then(order => {
        if (!order) {
          this.snackBar.open(this.translate.instant('order.notFound.message'), 'x')
        }
        return this.router.navigate([this.organizationService.currentOrganization.oid, 'order', order.id]);
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public goToAction(): void {
    if (!this.smallLayout) {
      return;
    }
    this.menuOpened = false;
  }
}
