import {FirestoreDataConverter} from '@angular/fire/firestore';

export class FileImportTask {

  id: string
  transactionId: string;
  inProgress: boolean;
  error: string;
  date: Date;

  constructor(task?: Partial<FileImportTask>) {
    this.id = task?.id ?? null;
    this.transactionId = task?.transactionId ?? '';
    this.inProgress = task?.inProgress ?? false;
    this.error = task?.error ?? '';
    this.date = task?.date ?? null;
  }


  static converter: FirestoreDataConverter<FileImportTask> = {
    toFirestore: task => ({
      id: task.id,
      transactionId: task.transactionId,
      inProgress: task.inProgress,
      error: task.error,
      date: task.date,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new FileImportTask({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null
      })
    }
  };
}
