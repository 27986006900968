import {FirestoreDataConverter} from '@angular/fire/firestore';
import {SettingsEnum} from '../enums/settings.enum';

export abstract class Settings {
  abstract type: SettingsEnum;
  abstract converter: FirestoreDataConverter<Settings>;
  id: string;

  protected constructor(data?: Partial<Settings>) {
    this.id = data?.id;
  }
}
