import { Injectable } from '@angular/core';
import { Settings } from '../models/settings.base';
import { Order } from '../models/order.model';
import { CallasSettings } from '../models/settings-callas.model';
import { MultipressSettings } from '../models/settings-multipress.model';
import { DeliverySetting, PrinterSetting } from '../models/settings-okto.model';
import { TransportSettings } from '../models/settings-transport.model';
import { Address } from '../models/address.model';
import { NumberHelper } from '../helpers/number.helper';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  Firestore, getDoc,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  where
} from '@angular/fire/firestore';
import { SiteModel } from '../models/site.model';
import { BusinessUnitModel } from '../models/business-unit.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Order service
 */
export class SettingsService {

  constructor(private firestore: Firestore) {
  }

  private settings: { [key: string]: BehaviorSubject<Settings> } = {};
  private _unsubscribe: { [key: string]: Unsubscribe } = {};
  private sites$: BehaviorSubject<SiteModel[]>;
  private unsubscribeSites: Unsubscribe;
  private businessUnits$: BehaviorSubject<BusinessUnitModel[]>;
  private unsubscribeBusinessUnits: Unsubscribe;

  public sites(): BehaviorSubject<SiteModel[]> {
    if (!this.sites$) {
      this.sites$ = new BehaviorSubject<SiteModel[]>([]);
      const q = query(collection(this.firestore, 'sites')).withConverter(SiteModel.converter);
      this.unsubscribeSites = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          this.sites$.next([]);
        }
        this.sites$.next(snapshot.docs.map(doc => doc.data()));
      });
    }
    return this.sites$;
  }

  public upsertSite(site: SiteModel): Promise<void> {
    const col = collection(this.firestore, 'sites');
    const ref = (site.id ? doc(col, site.id) : doc(col)).withConverter(SiteModel.converter);
    return setDoc(ref, site, {merge: true});
  }

  public deleteSite(id: string): Promise<void> {
    return deleteDoc(doc(this.firestore, 'sites', id));
  }

  public businessUnits(): BehaviorSubject<BusinessUnitModel[]> {
    if (!this.businessUnits$) {
      this.businessUnits$ = new BehaviorSubject<BusinessUnitModel[]>([]);
      const q = query(collection(this.firestore, 'business-units')).withConverter(BusinessUnitModel.converter);
      this.unsubscribeBusinessUnits = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          this.businessUnits$.next([]);
        }
        this.businessUnits$.next(snapshot.docs.map(doc => doc.data()));
      });
    }
    return this.businessUnits$;
  }

  public upsertBusinessUnit(businessUnit: BusinessUnitModel): Promise<void> {
    const col = collection(this.firestore, 'business-units');
    const ref = (businessUnit.id ? doc(col, businessUnit.id) : doc(col)).withConverter(BusinessUnitModel.converter);
    return setDoc(ref, businessUnit, {merge: true});
  }

  public deleteBusinessUnit(id: string): Promise<void> {
    return deleteDoc(doc(this.firestore, 'business-units', id));
  }

  /**
   * Save settings
   * @param settings The settings to save
   */
  public async save(settings: Settings): Promise<void> {
    const ref = doc(this.firestore, 'settings', settings.type).withConverter(settings.converter);
    return setDoc(ref, settings, {merge: true});
  }

  /**
   * Get a settings by id
   * @returns The settings
   */
  async getTransportSettings(): Promise<TransportSettings> {
    const newSettings = new TransportSettings();
    const ref = doc(this.firestore, 'settings', newSettings.type)
      .withConverter(newSettings.converter);
    const res = await getDoc(ref);
    if (!res.exists()) {
      return null;
    }
    return res.data();
  }

  /**
   * Get a settings by id
   * @param settingsType
   */
  public get<T extends new(data?: object) => U, U extends Settings>(settingsType: T): BehaviorSubject<Settings> {
    const newSettings = new settingsType();
    if (!this.settings[newSettings.type]) {
      try {
        this.settings[newSettings.type] = new BehaviorSubject(newSettings);
        const q = query(collection(this.firestore, 'settings'),
          where(documentId(), '==', newSettings.type)).withConverter(newSettings.converter);
        this._unsubscribe[newSettings.type] = onSnapshot(q, snapshot => {
          if (snapshot.empty) {
            this.settings[newSettings.type].next(new settingsType());
          }
          const doc = snapshot.docs[0];
          this.settings[newSettings.type].next(doc.data());
        });
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
    return this.settings[newSettings.type];
  }

  /**
   * Get the samba path to this order
   * @param order
   */
  getSambaOrderPath(order: Order): Observable<string> {
    return this.get(CallasSettings)
      .pipe(
        filter((settings: CallasSettings) => !!settings.workflowPathTemplate),
        map((settings: CallasSettings) => {
          const smbShareLink = settings.workflowSmbShare
            .replace(/^\\\\/, 'smb://')
            .replace(/\\/g, '/');
          const orderPath = settings.workflowPathTemplate
            .replace(/\\/g, '/')
            .replace(/#YEAR#/g, String(order.date.getFullYear()))
            .replace(/#MONTH#/g, `0${order.date.getMonth() + 1}`.slice(-2))
            .replace(/#COMPANY#/g, order.company)
            .replace(/#JOB_NUMBER#/g, order.transactionId);
          return `${smbShareLink}/${orderPath}`;
        }));
  }

  /**
   * Check if Delivery settings are valid
   * @param deliverySetting
   */
  isDeliveryValid(deliverySetting: DeliverySetting): boolean {
    return deliverySetting && NumberHelper.isPositiveInteger(deliverySetting.defaultDeliveryTime);
  }

  /**
   * Check if printers settings are valid
   * @param printerSetting
   */
  isPrintersValid(printerSetting: PrinterSetting): boolean {
    return printerSetting &&
      NumberHelper.isPositiveOrZero(printerSetting.printNumber) && printerSetting.userOverrides &&
      printerSetting.userOverrides.every(override => override.user && override.printer && override.site.length);
  }

  /**
   * Check if MultipressSettings is valid
   * @param multipress
   */
  isMultipressValid(multipress: MultipressSettings): boolean | string {
    return multipress?.waitQuotationStates && multipress.waitQuotationStates.length !== 0 &&
      multipress?.waitFileStates && multipress.waitFileStates.length !== 0 &&
      multipress.processingState && multipress.filesId && multipress.scriptId &&
      multipress.formatId && multipress.waitBatState && multipress.archiveState &&
      multipress.batRefusedState &&  multipress.batAcceptedState &&
      multipress.quotationRefusedState &&  multipress.quotationAcceptedState &&
      multipress.contactsId && multipress.readyToShipState;
  }

  /**
   * Check if CallasSettings is valid
   * @param callas
   */
  isCallasValid(callas: CallasSettings) {
    return callas?.workflowDir && callas.workflowSmbShare && callas.workflowPathTemplate && callas.productionFolder &&
      callas.paoFolder && callas.customerFolder && callas.batFolder && callas.warnScripts;
  }

  /**
   * Check if TransportSettings is valid
   * @param transport
   */
  public isTransportValid(transport: TransportSettings): boolean {
    for (const siteSetting of transport.siteSettings) {
      if (!this.isAddressValid(siteSetting.address)) {
        return false;
      }
      if (siteSetting.dpd.enabled) {
        if (!siteSetting.dpd.customerNumber || !siteSetting.dpd.countryCode || !siteSetting.dpd.centerNumber) {
          return false;
        }
      }
      if (siteSetting.schenker.enabled) {
        if (!siteSetting.schenker.accountNumber) {
          return false;
        }
      }
      if (siteSetting.geodis.enabled) {
        if (!siteSetting.geodis.code || !siteSetting.geodis.customerCode) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Check if address is valid
   * @param address
   */
  isAddressValid(address: Address) {
    return address.company && address.name && address.street1 && address.zip && address.city && address.country && address.email && address.phone;
  }

  public unsubscribe() {
    if (this.unsubscribeSites) {
      this.unsubscribeSites();
    }
    this.sites$ = null;
    if (this.unsubscribeBusinessUnits) {
      this.unsubscribeBusinessUnits();
    }
    this.sites$ = null;
    for (const unsubscribe of Object.values(this._unsubscribe)) {
      if (unsubscribe) {
        unsubscribe();
      }
      this._unsubscribe = {};
      this.settings = {};
    }
  }
}
