import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';

export class Cutting {
  id: string;
  erpId: string;
  description: string;
  type: CuttingEnum;

  constructor(data?: Partial<Cutting>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.type = data?.type ?? CuttingEnum.STRAIGHT;
  }

  static toFirestore(cutting: Cutting) {
    return {
      id: cutting.id,
      erpId: cutting.erpId,
      description: cutting.description,
      type: cutting.type,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Cutting(snapToData(snapshot, {idField: 'id'}));
  }
}

export enum CuttingEnum {
  STRAIGHT = 'straight',
  TO_SHAPE = 'shape',
}
