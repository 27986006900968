import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  DocumentReference,
  Firestore,
  orderBy,
  query,
  Query,
  where
} from '@angular/fire/firestore';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  list,
  ref,
  Storage,
  uploadBytesResumable,
  UploadTask,
  getBytes,
  uploadBytes,
  ListResult
} from '@angular/fire/storage';
import { ConvertTask } from "../models/convert-task.model";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private storage: Storage, private firestore: Firestore) {
  }

  /**
   * Check if a file exists from its path
   * @param path Firebase storage path
   * @return True or false it the exists or not
   */
  public exists(path: string): Promise<boolean> {
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef)
      .then(() => true)
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  /**
   * Get a downloadable url of a storage file
   * @param path Firebase storage path
   * @return The file URL
   */
  public getFileUrl(path: string): Promise<string> {
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }

  /**
   * Return [heigth, width] of the image
   * @param file
   */
  public getImageSize(file: File): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
      if (!file.type.startsWith('image/')) {
        return reject('not an image');
      }
      const reader = new FileReader();
      reader.onload = fileEvent => {
        const image = new Image();
        image.src = fileEvent.target.result as string;
        image.onload = imageEvent => {
          resolve([imageEvent.currentTarget['height'], imageEvent.currentTarget['width']])
        }
      };
      reader.readAsDataURL(file);
    })
  }

  /**
   * Upload a file to Firebase storage
   * @param file The file to upload
   * @param path Firebase storage path
   * @return The upload task to listen progress and state
   */
  public uploadFile(file: Blob | Uint8Array | ArrayBuffer, path: string): UploadTask {
    const storageRef = ref(this.storage, path);
    return uploadBytesResumable(storageRef, file);
  }

  /**
   * Remove a file from Firebase storage
   * @param path Firebase storage path
   */
  public removeFile(path: string): Promise<void> {
    const storageRef = ref(this.storage, path);
    return deleteObject(storageRef);
  }

  public async copyFile(source: string, target: string): Promise<string> {
    const sourceRef = ref(this.storage, source);
    const targetRef = ref(this.storage, target);
    const sourceBytes = await getBytes(sourceRef);
    const res = await uploadBytes(targetRef ,sourceBytes);
    return getDownloadURL(res.ref)
  }

  public list(prefix: string, maxResults: number, pageToken: string): Promise<ListResult> {
    const storageRef = ref(this.storage, prefix);
    return list(storageRef, {maxResults, pageToken});
  }

  /**
   * Remove a file from Firebase storage
   * @param path Firebase storage path
   */
  public async removeFolder(path: string): Promise<void> {
    const storageRef = ref(this.storage, path);
    const objects = await listAll(storageRef);
    for (const item of objects.items) {
      await deleteObject(item);
    }
  }

  /**
   * Create a task on firestore to convert a file (JPG || PNG) to PDF
   * @param path Firebase storage path
   * @param extension The extension of the file to convert
   * @return The Created document reference
   */
  public createConvertTask(path: string, extension: 'JPG' | 'PNG'): Promise<DocumentReference> {
    const ref = collection(this.firestore, 'convert-tasks').withConverter(ConvertTask.converter);
    return addDoc(ref, new ConvertTask({path, extension, date: new Date()}));
  }

  /**
   * Get subscription to the files conversions task
   * @param path Firebase storage path
   * @return The firestore query to get conversions tasks
   */
  // TODO: Keep Query ?
  public getConvertTask(path: string): Query<ConvertTask> {
    return query(collection(this.firestore, 'convert-tasks'),
      where('path', '==', path),
      orderBy('date', 'desc'))
      .withConverter(ConvertTask.converter);
  }
}
