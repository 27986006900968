import {Component, Inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-update-app',
  templateUrl: './update-app.component.html'
})
export class UpdateAppComponent {
  constructor(@Inject(DOCUMENT) public document: Document) {
  }
}
